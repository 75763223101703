/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1fcm7j9 bg--bottom --parallax pb--50 pt--50" anim={null} name={"intro"} animS={null} style={{"minHeight":"100vh"}} layout={"l1"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/3bdab7ee1323430c974794d447d7f653_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3bdab7ee1323430c974794d447d7f653_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3bdab7ee1323430c974794d447d7f653_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3bdab7ee1323430c974794d447d7f653_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3bdab7ee1323430c974794d447d7f653_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3bdab7ee1323430c974794d447d7f653_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3bdab7ee1323430c974794d447d7f653_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3bdab7ee1323430c974794d447d7f653_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1 pb--40 pt--40" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s7 --center" anim={"2"} animS={"7"} style={{"maxWidth":800}}>
              
              <Title className="title-box fs--154" style={{"maxWidth":1172}} content={"Merry<br>Christmas"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center ff--1 fs--72 w--300 swpf--uppercase ls--004 lh--1 mt--16" style={{"maxWidth":500}} content={"&amp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box swpf--uppercase mt--16" style={{"maxWidth":500}} content={"happy<br>New Year"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":500}} content={"from<br>Novak family"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/b247a11380b74482ae1f6d600b9b22ab.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":240}} srcSet={""} content={null}>
              </Image>

              <Text className="text-box mt--40" style={{"maxWidth":500}} content={"Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}